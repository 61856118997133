.bg-y1{
    background-color: #f4ebc0;
}

.bg-y2{
    background-color: #e5dbae;
}

.bg-y3{
    background-color: #d7c988;
}

.bg-y4{
    background-color: #322a02;
}

.bg-g1{
    background-color: #ccccc7!;
}

body{
    background-color: #eee;
}